import React from "react";

const Terms = () => {
  return (
    <div>
      <div className="pp-1">
        <div>
          <h1 className="fn__maintitle  text-pp" data-text="  Terms of Use for Xeno Learning">
          Terms of Use for Xeno Learning

          </h1>
        </div>
        <div className="privacy-d2">
          <div className="privacy-d3">
            <p>
            Welcome to Xeno Learning, an online coding coaching service. These Terms of Use govern your use of our coaching service. By using our service, you agree to these Terms of Use. If you do not agree to these Terms of Use, you may not use our service.

            </p>
            <p>
            Use of Service
Xeno Learning provides an online coaching service for coding and programming. You may use our service for your personal or educational purposes only. You may not use our service for any commercial or illegal purposes.

            </p>
            <p>
            Registration and Account
To use our service, you must register and create an account. You must provide accurate and complete information when you register. You are responsible for maintaining the security of your account and password. You may not share your account with others.

            </p>
            <p>
            Payment and Fees
Our coaching service may be offered for a fee. You must pay all fees in advance. We may change our fees at any time, but we will notify you in advance of any changes.

            </p>
            <p>
            Intellectual Property
All content on our coaching service, including text, graphics, logos, images, and software, is the property of Xeno Learning or its licensors and is protected by copyright, trademark, and other intellectual property laws. You may not use our content for any commercial or illegal purposes.


            </p>
            <p>
            Code of Conduct
You must follow our Code of Conduct when using our service. You may not use our service to harass, abuse, or threaten others. You may not post or share any illegal or inappropriate content. We reserve the right to terminate your account if you violate our Code of Conduct.

            </p>
            <p>
            Disclaimers and Limitations of Liability
We make no warranties or representations about the accuracy or completeness of our coaching service. We are not responsible for any errors or omissions in our coaching service. We are not liable for any damages arising from your use of our coaching service.

            </p>
            <p>
            Changes to Terms of Use
We may update these Terms of Use from time to time. If we make significant changes, we will notify you by email or by posting a notice on our website.

            </p>
            <p>
            Contact Us
If you have any questions or concerns about these Terms of Use, you can contact us at support@xenolearning.com.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
