import React from "react";
import { Link, useNavigate } from "react-router-dom";
const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer id="footer">
      <div className="container">
        <div className="footer">
          <div className="left_part">
            <p>
              Copyright © 2023 Xeno Learning .<span>All rights reserved.</span>
            </p>
          </div>
          <div className="right_part">
            <ul>
              <li>
                <span onClick={() => navigate("/PrivacyPolicy")}>
                  <span className="creative_link">Privacy Policy</span>
                </span>
              </li>
           
              <li>
                <span onClick={() => navigate("/TermsOfUse")}>
                  <span className="creative_link">  Terms Of Use</span>
                </span>
              </li>
              
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
