import React from "react";
import WaterHero from "../../Features/WaterHero/WaterHero";
import About from "../About/About";
import Navbar from "../../layout/Navbar";
import Collection from "../../Features/Collection/Collection";
import Faq from "../../Features/Faq/Faq";
import SectionDivider from "../../Features/SectionDivider/SectionDivider";
import RoadMapStep from "../../Features/RoadMapStep/RoadMapStep";
import Contact from "../Contact/Contact";
import News from "../../Features/News/News";
import Footer from "../../layout/Footer";
const Home = () => {

  
  return (
    <div>
<Navbar/>
 
      <section
     id="section1"
      >
        <WaterHero />
      </section>
      <section
     id="section2"
      
      >
        <About />
      </section>
      {/* <section
     id="section3"
      
      >
        <Collection/>
      </section> */}
      <section>
        <Faq/>
      </section>
      <section>
        <SectionDivider/>
      </section>
      <section>
      {/* <RoadMapStep/> */}

      </section>
      <section
     id="section4"
      
      >
        <News/>
      </section>
      <section>
        <SectionDivider/>
      </section>
      <section
     id="section5"
      
      >
      <Contact/>
   
      </section>
      <section>
        {/* <Footer/> */}
      </section>
    </div>
  );
};

export default Home;
