import React from 'react'
import './Term.css'

const PrivacyPolicy = () => {
  return (
    <div>


    <div className='pp-1'>
<div>
  <h1  className="fn__maintitle  text-pp" data-text="  Privacy Policy">
  Privacy Policy
  </h1>
</div>
<div className='privacy-d2'>

  <div className='privacy-d3'>

  <p>
  Xeno Learning is committed to protecting the privacy of our users. This Privacy Policy explains how we collect, use, and protect your personal information when you use our coding online coaching service.

  </p>
  <p>
  Information We Collect
We may collect personal information from you when you sign up for our coaching service, including your name, email address, and payment information. We may also collect non-personal information, such as your IP address, browser type, and operating system, to help us improve our service.

  </p>
  <p>
  How We Use Your Information
We use your personal information to provide you with our coaching service, communicate with you about your coaching sessions and assignments, and improve our service. We may also use your information for marketing purposes, such as to send you promotional emails or offers.

  </p>
  <p>
  How We Protect Your Information
We take reasonable measures to protect your personal information from unauthorized access, disclosure, or destruction. We use industry-standard encryption technology to secure your data, and limit access to your information to our authorized employees and contractors who need to know that information to operate our service.


  </p>
  <p>
  Sharing Your Information
We may share your personal information with our service providers, such as payment processors or email marketing providers, to help us provide our service. We may also share your information if required by law or to protect our legal rights.

  </p>
  <p>
  Your Choices
You can choose to opt out of our marketing communications at any time by clicking the unsubscribe link in our emails. You can also request to delete your personal information by contacting us at no-replyy@xenolearning.com.

  </p>
  <p>
  Changes to Our Privacy Policy
We may update our Privacy Policy from time to time. If we make significant changes, we will notify you by email or by posting a notice on our website.

  </p>
  <p>

Contact Us
If you have any questions or concerns about our Privacy Policy, you can contact us at no-reply@xenolearning.com.
  </p>
</div>
</div>

</div>

    </div>
  )
}

export default PrivacyPolicy